import axios from "axios";

export const sendRequest = (url, method, headers, body) => {
  const request = {
    baseURL: endpoint,
    url: url,
    method: method,
    headers: headers ? headers : defaultHeader(),
    data: body ? JSON.stringify(body) : {},
    timeout: 5000
  };

  return axios(request);
};
//bamboo
//export const endpoint = process.env.NODE_ENV ==='production' ? "http://localhost:3000":"http://localhost:3000";
//export const endpoint = process.env.NODE_ENV ==='production' ? "http://10.128.126.77:9090":"http://10.128.126.77:9090";
//export const endpoint = process.env.NODE_ENV ==='production' ? "https://10.128.106.115":"https://10.128.106.115";
export const endpoint = process.env.NODE_ENV ==='production' ? "https://veribackend.sgk.gov.tr":"https://veribackend.sgk.gov.tr";
//export const endpoint = process.env.NODE_ENV ==='production' ? "http://localhost:9005":"http://localhost:9005";


export const defaultHeader = () => {
  return {
    "Content-Type": "application/json"
  };
};
