import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import confirm from "../actions/confirm";
import Confirm from "../components/confirm";
import Table from "../components/table";
import Map from "../components/map";
import Metadata from "../components/metadata";
import Charts from "../components/charts";

class PreviewView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmed: false,
      activeType: 0,
      selectedData: {}
    };

    this.renderView = this.renderView.bind(this);
    this.onSubmitClicked = this.onSubmitClicked.bind(this);
  }

  componentDidMount() {
    const { selectedData } = this.props;

    if(selectedData.indicators.length === 0 || selectedData.cities.length === 0 || selectedData.dates.length === 0) {
      this.props.setConfirm(false);
    }
  }

  onPreviewTabClicked(e, id) {
    e.preventDefault();
    this.setState({ activeType: id });
  }

  onSubmitClicked() {
    this.setState({
      isConfirmed: true,
      activeType: 1
    }, () => {
      this.props.setConfirm(true);
    });
  }

  renderView({ type }) {
    const { selectedData, selectedLayouts } = this.props;
    let RenderedComponent = null;

    switch (type) {
      case 0:
        RenderedComponent = (
          <Confirm submit={this.onSubmitClicked} selectedData={selectedData} />
        );
        break;
      case 1:
        RenderedComponent = <Table selectedData={selectedData} />;
        break;
      case 2:
        RenderedComponent = (
          <Charts
            selectedData={selectedData}
            selectedLayouts={selectedLayouts}
          />
        );
        break;
      case 3:
        RenderedComponent = (
          <Map
            id="Turkey"
            selectedData={selectedData}
            selectedLayouts={selectedLayouts}
          />
        );
        break;
      case 4:
        RenderedComponent = <Metadata selectedData={selectedData} />;
        break;
      default:
        break;
    }

    return RenderedComponent;
  }

  render() {
    const { activeType } = this.state;
    const { selectedData } = this.props;

    return (
      <section className="preview-view">
          <div className="d-flex flex-row float-right preview-tab-menu mt-2">
            <a
              className={["nav-link ml-3", activeType === 1 ? "active" : ""].join(
                " "
              )}
              href="/"
              onClick={e => {
                this.onPreviewTabClicked(e, 1);
              }}
            >
              <span className="acshb-icon-table  pl-1" /> Tablo
            </a>
            <a
              className={["nav-link", activeType === 2 ? "active" : ""].join(
                " "
              )}
              href="/"
              onClick={e => {
                this.onPreviewTabClicked(e, 2);
              }}
            >
              <span className="acshb-icon-area-chart pl-1" /> Grafik
            </a>
            <a
              className={["nav-link", activeType === 3 ? "active" : ""].join(
                " "
              )}
              href="/"
              onClick={e => {
                this.onPreviewTabClicked(e, 3);
              }}
            >
              <span className="acshb-icon-map-pin1 pl-1" /> Harita
            </a>
            <a
              className={["nav-link", activeType === 4 ? "active" : ""].join(
                " "
              )}
              href="/"
              onClick={e => {
                this.onPreviewTabClicked(e, 4);
              }}
            >
              <span style={{fontSize: "1.2em"}} className="acshb-icon-question-circle-o pl-1" /> Metaveri
            </a>
          </div>
        {
          //<Submenu />
        }
        <this.renderView type={(selectedData.indicators.length === 0 || selectedData.cities.length === 0 || selectedData.dates.length === 0) ? 0 : activeType} />
      </section>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setConfirm: data => dispatch(confirm(data))
  };
};

const mapStateToProps = state => {
  return {
    selectedData: state.selectedData,
    selectedLayouts: state.selectedLayouts
  };
};

PreviewView.propTypes = {
  selectedData: PropTypes.object,
  selectedLayouts: PropTypes.object,
  setConfirm: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewView);
