import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "./components/loader";
import Toolbar from "./components/toolbar";
import MainView from "./views/main";
import Footer from "./components/footer";
import "./styles/global.scss";
import { ToastContainer } from "react-toastify";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading } = this.props;

    return (
      <div className="app">
        <ToastContainer />
        {
          loading ?
          <Loader />
          :
          null
        }
        <Toolbar />
        <MainView />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading
  };
};

App.propTypes = {
  loading: PropTypes.bool
};

export default connect(
  mapStateToProps,
  null
)(App);