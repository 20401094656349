import React from "react";

function Footer(props) {
  return (
    <footer className="footer">
      <div className="border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex bd-highlight">
                <div className="p-2 bd-highlight">
                  <a
                    href="https://ailevecalisma.gov.tr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://ailevecalisma.gov.tr/media/2983/acb-logo-v1.svg"
                      width="90"
                      height="90"
                      alt="T.C. Aile, Çalışma ve Sosyal Hizmetler Bakanlığı Logo"
                    />
                  </a>
                </div>
                <div className="p-2 bd-highlight">
                  <a
                    href="http://www.sgk.gov.tr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://www.ailevecalisma.gov.tr/media/12053/sgk-logo-vector.svg"
                      width="100"
                      height="90"
                      alt="Sosyal Güvenlik Kurumu"
                    />
                  </a>
                </div>
                <div className="ml-auto bd-highlight pt-5">
                  <small className="text-muted">v2.5.2b</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-2">
        {/*<div className="row">*/}
        {/*  <div className="col-12 mt-3 mb-3 footer-link">*/}
        {/*    <div className="row text-center">*/}
        {/*      <a href="/" className="col-md-1 col-sm-12 border-right">*/}
        {/*        Yasal*/}
        {/*      </a>{" "}*/}
        {/*      <a href="/" className="col-md-2 col-sm-12 border-right">*/}
        {/*        Bilgiye Erişim*/}
        {/*      </a>{" "}*/}
        {/*      <a href="/" className="col-md-1 col-sm-12 border-right">*/}
        {/*        İşler*/}
        {/*      </a>{" "}*/}
        {/*      <a href="/" className="col-md-2 col-sm-12 border-right">*/}
        {/*        Site Haritası*/}
        {/*      </a>*/}
        {/*      <a href="/" className="col-md-1 col-sm-12">*/}
        {/*        İletişim*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className="container mt-3 mb-3 text-center text-muted">
        <small>
          © 2019 T.C. Aile, Çalışma ve Sosyal Hizmetler Bakanlığı, Bilgi İşlem
          Dairesi Başkanlığı. Tüm Hakları Saklıdır.
        </small>
      </div>
      <div className="container mt-3 mb-3 text-center text-muted">
      <small>
          Geri bildirimlerinizi <a href="mailto:veri@sgk.gov.tr" target="_blank">veri@sgk.gov.tr</a> adresine gönderebilirsiniz.
        </small>
      </div>
    </footer>
  );
}

export default Footer;
