import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function Confirm(props) {
  const onSubmitClicked = () => {
    if (props.submit) props.submit();
  };
  const canSubmit =
    props.selectedData.cities.length > 0 &&
    props.selectedData.dates.length > 0 &&
    props.selectedData.indicators.length > 0;
  return (
    <div className="section-confirm-wrapper">
        <div className="preview-content">

        </div>
      <div className="section-confirm shadow-sm">
        <span className="text-center">
          Lütfen devam etmek için kalan değişkenleri seçiniz
        </span><br/><br/>
        <ul className="list-group">
          <li
            className={[
              "list-group-item",
              props.selectedData.indicators.length > 0 ? "confrimOkey" : ""
            ].join(" ")}
          >
            <span
                className={[ "",
                    props.selectedData.indicators.length > 0 ? "acshb-icon-check-circle-o" : "acshb-icon-dot-circle-o"
                ].join(" ")} /> Gösterge
          </li>
          <li
            className={[
              "list-group-item",
              props.selectedData.cities.length > 0 ? "confrimOkey" : ""
            ].join(" ")}
          >
           <span
               className={[ "",
                   props.selectedData.cities.length > 0 ? "acshb-icon-check-circle-o" : "acshb-icon-dot-circle-o"
               ].join(" ")} /> Şehir
          </li>
          <li
            className={[
              "list-group-item",
              props.selectedData.dates.length > 0 ? "confrimOkey" : ""
            ].join(" ")}
          >
          <span
              className={[ "",
                  props.selectedData.dates.length > 0 ? "acshb-icon-check-circle-o" : "acshb-icon-dot-circle-o"
              ].join(" ")} /> Tarih
          </li>
        </ul>
        <button
          className="btn btn-primary section-confirm-button"
          onClick={() => {
            onSubmitClicked();
          }}
          disabled={!canSubmit}
        >
          Değişiklikleri Uygula
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    selectedData: state.selectedData
  };
};

Confirm.propTypes = {
  selectedData: PropTypes.object,
  submit: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  null
)(Confirm);
