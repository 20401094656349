import React, { Component } from "react";
import PropTypes from "prop-types";

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
    this.onButtonClicked = this.onButtonClicked.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.onElementClicked = this.onElementClicked.bind(this);
    this.renderDropdownItem = this.renderDropdownItem.bind(this);
    this.sectionTitle = "";
  }

  onButtonClicked(e) {
    e.preventDefault();

    if (!this.props.disabled) {
      this.setState({ extended: true }, () => {
        document.addEventListener("click", this.closeDropdown);
      });
    }
  }

  closeDropdown() {
    this.setState({ extended: false }, () => {
      document.removeEventListener("click", this.closeDropdown);
    });
  }

  onElementClicked(item) {
    this.props.onSelectCallback(item);
  }

  renderDropdownItem({ item, icons, index }) { 
    if(item.type !== undefined && index === 1) {
      return (
        <React.Fragment>
          <li
        key={item.name}
        className="dropdown-item disabled"
      >
        {icons ? (
          <span
            className={[
              "dropdown-item-icon",
              "d-inline-block",
              "mr-3",
              item.icon
            ].join(" ")}
          />
        ) : null}
        Coğrafi Bölgeler <hr />
      </li>
      <li
      key={item.name}
      className="dropdown-item"
      onClick={() => {
        this.onElementClicked(item);
      }}
    >
      {icons ? (
        <span
          className={[
            "dropdown-item-icon",
            "d-inline-block",
            "mr-3",
            item.icon
          ].join(" ")}
        />
      ) : null}
      {item.title}
    </li>
        </React.Fragment>
      );
    }
    
    else if(item.type !== undefined && index === 8) {
      return (
        <React.Fragment>
          <li
        key={item.name}
        className="dropdown-item disabled"
      >
        {icons ? (
          <span
            className={[
              "dropdown-item-icon",
              "d-inline-block",
              "mr-3",
              item.icon
            ].join(" ")}
          />
        ) : null}
        İstatistiki Bölgeler <hr />
      </li>
      <li
          key={item.name}
          className="dropdown-item"
          onClick={() => {
            this.onElementClicked(item);
          }}
        >
          {icons ? (
            <span
              className={[
                "dropdown-item-icon",
                "d-inline-block",
                "mr-3",
                item.icon
              ].join(" ")}
            />
          ) : null}
          {item.title}
        </li>
        </React.Fragment>
      );
    }
    
    else {
      return (
        <li
          key={item.name}
          className="dropdown-item"
          onClick={() => {
            this.onElementClicked(item);
          }}
        >
          {icons ? (
            <span
              className={[
                "dropdown-item-icon",
                "d-inline-block",
                "mr-3",
                item.icon
              ].join(" ")}
            />
          ) : null}
          {item.title}
        </li>
      );
    }
  }

  render() {
    const { extended } = this.state;
    const { buttonClass, disabled, icons, items, title } = this.props;

    return (
      <div className={[
        "dropdown",
        this.props.className
      ].join(" ")}>
        <button
          title={title}
          className={[
            "btn dropdown-toggle",
            buttonClass ? buttonClass : "",
            disabled ? "disabled" : ""
          ].join(" ")}
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={extended}
          onClick={this.onButtonClicked}
        >
          {this.props.children}
        </button>
        <div
          className={extended ? "dropdown-menu show" : "dropdown-menu"}
          aria-labelledby="dropdownMenuButton"
        >
          <ul className="m-0 p-0">
            {items.map((item, i) => {
              return (
                <this.renderDropdownItem
                  key={item.value}
                  item={item}
                  icons={icons}
                  index={i}
                />
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  buttonClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  onSelectCallback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  icons: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired
    })
  ).isRequired,
  title: PropTypes.string
};

export default Dropdown;
