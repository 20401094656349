import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {loading} from "../actions/loading";
import {sendRequest} from "../services/httpService";
import {numberWithDots} from "../utils/utils";
import logo from "../assets/logoacshb.svg";
import SaveView from "../views/partialViews/saveView";

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            activeCity: props.selectedData.cities[0].name
        };
        this.fetchData = this.fetchData.bind(this);
        this.onCityChange = this.onCityChange.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillReceiveProps(nextProps) {
        this.fetchData();
    }

    fetchData() {
        const {setLoading} = this.props;

        const {selectedData} = this.props;
        const payload = {
            year: selectedData.dates.map(d => {
                return d.name;
            }),
            city: selectedData.cities.map(d => {
                return d.name;
            }),
            //city: [activeCity],
            category: selectedData.categories.map(d => {
                return d.name;
            }),
            indicator: selectedData.indicators.map(d => {
                return d.name;
            })
        };
        if (
            payload.indicator.length === 0 ||
            payload.city.length === 0 ||
            payload.year.length === 0
        )
            this.setState({data: []});
        else {
            setLoading(true);
            sendRequest("/data/statistic", "post", null, payload)
                .then(res => {
                    this.setState({data: res.data}, () => {
                        setLoading(false);
                    });
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    onCityChange(e) {
        this.setState(
            {
                activeCity: e.target.value
            },
            () => {
                this.fetchData();
            }
        );
    }

    render() {
        //const {selectedData} = this.props;
        const data = this.state.data;

        return (
            <div className="section-table preview-content">
                {/*<div className="col-12">*/}
                {/*    <form>*/}
                {/*        <div className="form-group">*/}
                {/*            <select className="form-control" onChange={this.onCityChange}>*/}
                {/*                {selectedData.cities.map(c => {*/}
                {/*                    return (*/}
                {/*                        <option key={c.name} value={c.name}>*/}
                {/*                            {c.name}*/}
                {/*                        </option>*/}
                {/*                    );*/}
                {/*                })}*/}
                {/*            </select>*/}
                {/*        </div>*/}
                {/*    </form>*/}
                {/*</div>*/}

                {data.data && data.data.length > 0 ? (
                    <div className="preview-content table-responsive">
                        <table className="table table-hovered first-table" width="100%">
                            <thead>
                            <tr>
                                <th>Kategori</th>
                                <th>Gösterge</th>
                                <th>Değer</th>
                                <th>Şehir</th>
                                <th>Bölge</th>
                                {data.dates.map(d => {
                                    return <th key={d.name}>{d}</th>;
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {data.data.map(d => {
                                return (
                                    <tr key={d.id}>
                                        <td>{d.category}</td>
                                        <td>{d.indicator}</td>
                                        <td>{d.unit}</td>
                                        <td>{d.city}</td>
                                        <td>{d.region}</td>
                                        {d.values.map(v => {
                                            return <td key={d.key}>{numberWithDots(v)}</td>;
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        <div className="clearfix"/>
                        <div className="row">
                            <div className="row">
                                <div className="col-12 float-left ml-4 mb-4">
                                    <small className="float-left text-muted kaynak-text">Kaynak:</small>
                                    <img src={logo} alt="logo" height="20"/>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : <p style={{minHeight: "300px"}}>Veri Yok</p>}
                {data.data && data.data.length > 0 ? (
                    <div>
                        <SaveView/>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: data => dispatch(loading(data))
    };
};

Table.propTypes = {
    selectedData: PropTypes.object,
    setLoading: PropTypes.func
};

export default connect(
    null,
    mapDispatchToProps
)(Table);
