import { combineReducers } from "redux";
import categoryData from "./categoryData";
import selectedData from "./selectedData";
import selectedLayouts from "./selectedLayouts";
import loading from "./loading";
import confirm from "./confirm";

const rootReducer = combineReducers({
  categoryData,
  selectedData,
  selectedLayouts,
  loading,
  confirm
});

export default rootReducer;
