import React, { Component } from "react";
import PropTypes from "prop-types";

class Tabview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId:
        props.data.length === 0 ? "" : props.data[props.defaultSelectedIndex].id
    };

    this.renderComponents = this.renderComponents.bind(this);
    this.onNavLinkClicked = this.onNavLinkClicked.bind(this);
    this.renderNavItems = this.renderNavItems.bind(this);
  }

  renderNavItems({ data }) {
    const { selectedId } = this.state;

    return data.map(d => {
      return (
        <li
          key={d.id}
          className={["nav-item", d.id === selectedId ? "active" : ""].join(
            " "
          )}
          onClick={e => this.onNavLinkClicked(e, d)}
        >
          <a
            className={["nav-link", d.id === selectedId ? "active" : ""].join(
              " "
            )}
            id={`${d.id}-tab`}
            href="/"
            data-toggle="tab"
            role="tab"
            aria-controls={d.id}
            aria-selected={d.id === selectedId ? "true" : "false"}
          >
            {d.title}
          </a>
        </li>
      );
    });
  }

  renderComponents({ data }) {
    const { selectedId } = this.state;

    return data.map(d => {
      return (
        <div
          key={d.id}
          className={[
            "tab-pane fade",
            d.id === selectedId ? "show active" : ""
          ].join(" ")}
          id={d.id}
          role="tabpanel"
          aria-labelledby={`${d.id}-tab`}
        >
          <d.component />
        </div>
      );
    });
  }

  onNavLinkClicked(e, data) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      selectedId: data.id
    });
  }

  render() {
    const { data } = this.props;

    return (
      <div className="tabview">
        <ul className="nav nav-tabs mb-3" role="tablist">
          <this.renderNavItems data={data} />
        </ul>
        <div className="tab-content">
          <this.renderComponents data={data} />
        </div>
      </div>
    );
  }
}

Tabview.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      component: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  buttons: PropTypes.bool,
  vertical: PropTypes.bool,
  defaultSelectedIndex: PropTypes.number.isRequired
};

export default Tabview;
