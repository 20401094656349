import * as types from "../actions/types";

const initialState = {
  categories: [],
  indicators: [],
  units: [],
  cities: [],
  dates: []
};

const selectedData = (state = initialState, action) => {
  let result = null;

  switch (action.type) {
    case types.selectedData:
      if (action.payload[0] === "category") {
        state.categories = action.payload[1];
        result = Object.assign({}, state);
      } else if (action.payload[0] === "city") {
        state.cities = action.payload[1];
        result = Object.assign({}, state);
      } else if (action.payload[0] === "date") {
        state.dates = action.payload[1];
        result = Object.assign({}, state);
      } else if (action.payload[0] === "indicator") {
        state.indicators = action.payload[1];
        result = Object.assign({}, state);
      } else if (action.payload[0] === "unit") {
        state.units = action.payload[1];
        result = Object.assign({}, state);
      }
      break;
    default:
      result = state;
      break;
  }

  return result;
};

export default selectedData;
