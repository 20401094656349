import * as types from "../actions/types";

const initialState = false;

const confirm = (state = initialState, action) => {
  let result = null;

  switch (action.type) {
    case types.confirm:
      result = action.payload;
      break;
    default:
      result = state;
      break;
  }

  return result;
};

export default confirm;