import React, { Component } from "react";
import axios from "axios";
import pdf from "../../assets/pdf.svg";
import xls from "../../assets/xls.svg";
import csv from "../../assets/csv.svg";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { PDF_EXC_CSV_INFO_MESSAGE } from "../../constants/constant_messages";

class SaveView extends Component {
  constructor(props) {
    super(props);
      //axios.defaults.baseURL = (process.env.NODE_ENV ==='production') ? "http://10.128.126.77:9090/":"http://10.128.126.77:9090/";
      //axios.defaults.baseURL = (process.env.NODE_ENV ==='production') ? "http://localhost:3000/":"http://localhost:3000/";
     axios.defaults.baseURL = (process.env.NODE_ENV ==='production') ? "https://veribackend.sgk.gov.tr/":"https://veribackend.sgk.gov.tr/";
      //axios.defaults.baseURL = (process.env.NODE_ENV ==='production') ? "http://localhost:9005/":"http://localhost:9005/";
    this.pdfIndir = this.pdfIndir.bind(this);
    this.excellIndir = this.excellIndir.bind(this);
    this.csvIndir = this.csvIndir.bind(this);
    this.getConvertedSelectedDateforRequest = this.getConvertedSelectedDateforRequest.bind(
      this
    );
    this.alertMessage = this.alertMessage.bind(this);
    this.state = {
      selectedData: {},

      data: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      selectedData: nextProps.selectedData
    };
  }

  getConvertedSelectedDateforRequest(selectedData) {
    const convertedData = {
      year: selectedData.dates.map(d => {
        return d.name;
      }),
      city: selectedData.cities.map(d => {
        return d.name;
      }),

      indicator: selectedData.indicators.map(d => {
        return d.name;
      })
    };
    return convertedData;
  }

  alertMessage() {
    if (
      this.state.selectedData.cities.length !== 0 &&
      this.state.selectedData.indicators.length !== 0 &&
      this.state.selectedData.dates.length !== 0
    )
      return true;
    else return false;
  }

  csvIndir() {
    if (this.alertMessage()) {
      let req = this.getConvertedSelectedDateforRequest(
        this.state.selectedData
      );

      axios
        .post("/data/statistic_csv", req, { responseType: "arraybuffer" })
        .then(res => {
          this.setState({ spinLoaded: true });

          if (res.status === 200) {
            toast("csv İndiriliyor", { autoClose: 2000 });
            let arrayBufferView = new Uint8Array(res.data);
            let newBlob = new Blob([arrayBufferView], { type: "text/csv" });
            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement("a");
            link.href = data;
            link.charset = "utf-8";
            let rightNow = new Date().toISOString().slice(0,10).replace(/-/g,"");
            link.download = "ACSHB_" + rightNow + ".txt"
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(err => {
          console.error(err);
        });
    } else toast.error(PDF_EXC_CSV_INFO_MESSAGE, { autoClose: 4000 });
  }

  pdfIndir() {
    let req = this.getConvertedSelectedDateforRequest(this.state.selectedData);

    if (this.alertMessage()) {
      axios
        .post("/data/statistic_pdf", req, { responseType: "arraybuffer" })
        .then(res => {
          this.setState({ spinLoaded: true });

          if (res.status === 200) {
            toast("Pdf İndiriliyor", { autoClose: 2000 });
            let arrayBufferView = new Uint8Array(res.data);
            let newBlob = new Blob([arrayBufferView], {
              type: "application/pdf"
            });
            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement("a");
            link.href = data;
            let rightNow = new Date().toISOString().slice(0,10).replace(/-/g,"");
            link.download = "ACSHB_" + rightNow

            link.click();
          }
        })
        .catch(err => {
          console.error(err);
        });
   } else toast.error(PDF_EXC_CSV_INFO_MESSAGE, { autoClose: 4000 });
  }

  excellIndir() {
    let req = this.getConvertedSelectedDateforRequest(this.state.selectedData);

    if (this.alertMessage()) {
      axios
        .post("/data/statistic_excel", req, { responseType: "arraybuffer" })
        .then(res => {
          if (res.status === 200) {
            toast("excell İndiriliyor", { autoClose: 2000 });

            this.setState({ data: res.data });
            let arrayBufferView = new Uint8Array(res.data);
            let newBlob = new Blob([arrayBufferView], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement("a");
            link.href = data;
            let rightNow = new Date().toISOString().slice(0,10).replace(/-/g,"");
            link.download = "ACSHB_" + rightNow;
            link.click();
          }
        })
        .catch(err => {
          console.error(err);
        });
    } else toast.error(PDF_EXC_CSV_INFO_MESSAGE, { autoClose: 4000 });
  }

  render() {
    return (
      <section className="save-view border-bottom">
        <div className="card">
          <div
            id="layout"
            className="collapse show"
            aria-labelledby="layoutBaslik"
            data-parent="#degiskenlerAccordion"
          >
            <div className="card-body text-center">
              <div className="row">

                <div className="col-4">
                  <div className="download-link" title="XLS Olarak İndir">
                    <img
                      className="export-link img-fluid"
                      width="70"
                      height="100"
                      onClick={this.excellIndir.bind(this)}
                      src={xls}
                      alt="xls"
                    />
                  </div>
                </div>

                <div className="col-4">
                  <div className="download-link" title="CSV Olarak İndir">
                    <img
                      className="export-link img-fluid"
                      width="70"
                      height="100"
                      src={csv}
                      onClick={this.csvIndir.bind(this)}
                      alt="Csv"
                    />
                  </div>
                </div>
              </div>

              {/*<div className="row">*/}
              {/*  <div className="col-4">*/}
              {/*    <button type="button" className="btn btn-outline-dark btn-sm mt-2">İndir</button>*/}
              {/*  </div>*/}
              {/*  <div className="col-4">*/}
              {/*    <button type="button" className="btn btn-outline-dark btn-sm mt-2">İndir</button>*/}
              {/*  </div>*/}
              {/*  <div className="col-4">*/}
              {/*    <button type="button" className="btn btn-outline-dark btn-sm mt-2">İndir</button>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {
    selectedData: state.selectedData
  };
};

SaveView.propTypes = {
  selectedData: PropTypes.object
};

export default connect(
  mapStateToProps,
  null
)(SaveView);
