import React, {Component} from "react";
import {sendRequest} from "../services/httpService";
import logo from '../assets/logoacshb.svg';

class Metadata extends Component {

    constructor(props) {
        super(props);
        this.state = {metaDataList: []};
        this.fetchMetaData();
    }

    componentWillReceiveProps(nextProps) {
        this.fetchMetaData();
    }

    fetchMetaData() {
        const {indicators} = this.props.selectedData;
        const payload = {
            indicator: indicators.map(d => {
                return d.name;
            })
        };

        sendRequest("/data/meta-data-list", "post", null, payload)
            .then(res => {
                this.setState({metaDataList: res.data});
            })
            .catch(err => {
                console.error(err);
            });
    }


    render() {
        const {metaDataList} = this.state;
        return (
            <div className="preview-content">
                {metaDataList.map(metaData => {
                    return (
                        <div className="table-responsive">
                        <table className="table table-hover">
                            <tbody>
                            <tr>
                                <th width="170">Gösterge<span className="float-right">:</span></th>
                                <td>{metaData.indicator}</td>
                            </tr>
                            <tr>
                                <th width="170">Değer<span className="float-right">:</span></th>
                                <td>{metaData.unit}</td>
                            </tr>
                            <tr>
                                <th width="170">Tanım<span className="float-right">:</span></th>
                                <td>{metaData.definition}</td>
                            </tr>
                            <tr>
                                <th width="170">Yayınlanma Sıklığı<span className="float-right">:</span></th>
                                <td>{metaData.frequency}</td>
                            </tr>
                            <tr>
                                <th width="170">Yayınlanma Takvimi<span className="float-right">:</span></th>
                                <td>{metaData.publish_schedule}</td>
                            </tr>
                            <tr>
                                <th width="170">Kaynak<span className="float-right">:</span></th>
                                <td>{metaData.source}</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    );
                })}

                <div className="row">
                    <div className="col-12 float-left ml-4 mb-4">
                        <small className="float-left text-muted kaynak-text">Kaynak: </small>
                        <img   src={logo}  alt="logo" height="20"/>
                    </div>

                </div>
            </div>


        )
    }
}

Metadata.propTypes = {};

export default Metadata;
