import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectedLayouts } from "../../actions/selectedLayouts";
import Bar1 from "../../assets/Bar1.svg";
import Bar2 from "../../assets/Bar2.svg";
import Bar3 from "../../assets/Bar3.svg";
import Bar4 from "../../assets/Bar4.svg";
import Bar5 from "../../assets/Bar5.svg";

class LayoutsView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onGraphicColorsClicked = this.onGraphicColorsClicked.bind(this);
    this.onGraphicTypesChange = this.onGraphicTypesChange.bind(this);
    this.onSizeClicked = this.onSizeClicked.bind(this);
  }

  onGraphicTypesChange(e) {
    this.props.selectedLayouts.graphics.type = e.target.value;
    this.props.setSelectedLayouts(this.props.selectedLayouts);
  }

  onGraphicColorsClicked(colors) {
    this.props.selectedLayouts.graphics.colors = colors;
    this.props.setSelectedLayouts(this.props.selectedLayouts);
  }

  onSizeClicked(size) {
    this.props.selectedLayouts.sizes = size;
    this.props.setSelectedLayouts(this.props.selectedLayouts);
  }

  render() {
    return (
      <section className="layout-view border-bottom">
        <div className="accordion" id="layoutAccordion">
          {/*<div className="card">*/}
          {/*  <div className="card-header" id="layoutBaslik">*/}
          {/*    <h2 className="mb-0">*/}
          {/*      <button*/}
          {/*        className="btn btn-link"*/}
          {/*        type="button"*/}
          {/*        data-toggle="collapse"*/}
          {/*        data-target="#layout"*/}
          {/*        aria-expanded="true"*/}
          {/*        aria-controls="layout"*/}
          {/*      >*/}
          {/*        <span className="acshb-icon-chevron-down card-icon" />{" "}*/}
          {/*        Yerleşim*/}
          {/*      </button>*/}
          {/*      <div className="card-metadata" />*/}
          {/*    </h2>*/}
          {/*  </div>*/}
          {/*  <div*/}
          {/*    id="layout"*/}
          {/*    className="collapse show"*/}
          {/*    aria-labelledby="layoutBaslik"*/}
          {/*    data-parent="#degiskenlerAccordion"*/}
          {/*  >*/}
          {/*    <div className="card-body">*/}
          {/*      <div*/}
          {/*        className="btn-group"*/}
          {/*        role="group"*/}
          {/*        aria-label="Basic example"*/}
          {/*      >*/}
          {/*        <button type="button" className="btn btn-secondary">*/}
          {/*          Popüler*/}
          {/*        </button>*/}
          {/*        <button type="button" className="btn btn-secondary">*/}
          {/*          Standart*/}
          {/*        </button>*/}
          {/*      </div>*/}

          {/*      <div*/}
          {/*        className="btn-group mt-3"*/}
          {/*        role="group"*/}
          {/*        aria-label="First group"*/}
          {/*      >*/}
          {/*        <button*/}
          {/*          type="button"*/}
          {/*          className="btn btn-secondary btn-sm mr-2"*/}
          {/*        >*/}
          {/*          <img className="" width="30" height="30" src={LA1} alt="" />*/}
          {/*        </button>*/}
          {/*        <button*/}
          {/*          type="button"*/}
          {/*          className="btn btn-secondary btn-sm mr-2"*/}
          {/*        >*/}
          {/*          <img className="" width="30" height="30" src={LA2} alt="" />*/}
          {/*        </button>*/}
          {/*        <button*/}
          {/*          type="button"*/}
          {/*          className="btn btn-secondary btn-sm mr-2"*/}
          {/*        >*/}
          {/*          <img className="" width="30" height="30" src={LA3} alt="" />*/}
          {/*        </button>*/}
          {/*        <button*/}
          {/*          type="button"*/}
          {/*          className="btn btn-secondary btn-sm mr-2"*/}
          {/*        >*/}
          {/*          <img className="" width="30" height="30" src={LA4} alt="" />*/}
          {/*        </button>*/}
          {/*        <button*/}
          {/*          type="button"*/}
          {/*          className="btn btn-secondary btn-sm mr-2"*/}
          {/*        >*/}
          {/*          <img className="" width="30" height="30" src={LA5} alt="" />*/}
          {/*        </button>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="card">
            <div className="card-header" id="graphicTypesBaslik">
              <h2 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#graphicTypes"
                  aria-expanded="false"
                  aria-controls="graphicTypes"
                >
                  <span className="acshb-icon-chevron-down card-icon" /> Grafik
                  Tipi & Stilleri
                </button>
              </h2>
            </div>
            <div
              id="graphicTypes"
              className="collapse show"
              aria-labelledby="graphicTypesBaslik"
              data-parent="#degiskenlerAccordion"
            >
              <div className="card-body">
                <select
                  id=""
                  name=""
                  className="form-control"
                  onChange={this.onGraphicTypesChange}
                >
                  <option value="1">Dikey Grafik</option>
                  <option value="2">Yatay Grafik</option>
                </select>

                <div
                  className="btn-group mt-3"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm mr-2"
                    onClick={e =>
                      this.onGraphicColorsClicked([
                        "#ed1c24",
                        "#b51117",
                        "#dee2e6"
                      ])
                    }
                  >
                    <img
                      className=""
                      width="30"
                      height="30"
                      src={Bar1}
                      alt=""
                    />
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm mr-2"
                    onClick={e =>
                      this.onGraphicColorsClicked([
                        "#54697d",
                        "#a3d0af",
                        "#639067"
                      ])
                    }
                  >
                    <img
                      className=""
                      width="30"
                      height="30"
                      src={Bar2}
                      alt=""
                    />
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm mr-2"
                    onClick={e =>
                      this.onGraphicColorsClicked([
                        "#892a26",
                        "#c14a20",
                        "#d16b2a"
                      ])
                    }
                  >
                    <img
                      className=""
                      width="30"
                      height="30"
                      src={Bar3}
                      alt=""
                    />
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm mr-2"
                    onClick={e =>
                      this.onGraphicColorsClicked([
                        "#d32923",
                        "#2176b3",
                        "#fd800b"
                      ])
                    }
                  >
                    <img
                      className=""
                      width="30"
                      height="30"
                      src={Bar4}
                      alt=""
                    />
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm mr-2"
                    onClick={e =>
                      this.onGraphicColorsClicked([
                        "#8d527c",
                        "#66aac4",
                        "#fc9d00"
                      ])
                    }
                  >
                    <img
                      className=""
                      width="30"
                      height="30"
                      src={Bar5}
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="card">*/}
          {/*  <div className="card-header" id="advanceBaslik">*/}
          {/*    <h2 className="mb-0">*/}
          {/*      <button*/}
          {/*        className="btn btn-link collapsed"*/}
          {/*        type="button"*/}
          {/*        data-toggle="collapse"*/}
          {/*        data-target="#advance"*/}
          {/*        aria-expanded="false"*/}
          {/*        aria-controls="advance"*/}
          {/*      >*/}
          {/*        <span className="acshb-icon-chevron-down card-icon" />*/}
          {/*        Gelişmiş*/}
          {/*      </button>*/}
          {/*    </h2>*/}
          {/*  </div>*/}
          {/*  <div*/}
          {/*    id="advance"*/}
          {/*    className="collapse"*/}
          {/*    aria-labelledby="advanceBaslik"*/}
          {/*    data-parent="#degiskenlerAccordion"*/}
          {/*  >*/}
          {/*    <div className="card-body">*/}
          {/*      <div className="row">*/}
          {/*        <div className="col-5">Sayfa Başına Satır:</div>*/}
          {/*        <div className="col-7">*/}
          {/*          <div*/}
          {/*            className="btn-group mr-sm"*/}
          {/*            role="group"*/}
          {/*            aria-label="Basic example"*/}
          {/*          >*/}
          {/*            <button className="btn btn-danger btn-sm">50</button>*/}
          {/*            <button type="button" className="btn btn-danger btn-sm">*/}
          {/*              100*/}
          {/*            </button>*/}
          {/*            <button type="button" className="btn btn-danger btn-sm">*/}
          {/*              150*/}
          {/*            </button>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedLayouts: state.selectedLayouts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedLayouts: data => dispatch(selectedLayouts(data))
  };
};

LayoutsView.propTypes = {
  selectedLayouts: PropTypes.object,
  setSelectedLayouts: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutsView);
