import React from "react";
import PropTypes from "prop-types";

const Modal = (props) => {
  return (
    props.show ? 
    <div className="modal popup" style={{display: (props.show) ? "block" : "none"}}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.title}</h5>
              <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close" onClick={props.hideCallback}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{props.children}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={props.hideCallback}>
                Kapat
              </button>
            </div>
          </div>
        </div>
      </div>
    :
    null
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  hideCallback: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default Modal;
