import React from "react";
import FilterItem from "../../components/filterItem";
import bolgeler from "../../constants/bolgeler";

const VariablesView = props => {
  return (
    <section className="variables-view border-bottom">
      <div className="accordion" id="degiskenlerAccordion">
        <FilterItem
          id="indicator"
          url="/data/indicators"
          parentId="degiskenlerAccordion"
          title="Gösterge"
          information
          show
          maxSelectSize={20}
          filterDatas={[
            {
              id: "tümü",
              title: "Tümü",
              value: 0
            },
            {
              id: "aktif",
              title: "Aktif",
              value: 1
            },
            {
              id: "pasif",
              title: "Pasif",
              value: 2
            },
            {
              id: "iş yeri",
              title: "İş Yeri",
              value: 3
            },
            {
              id: "sağlık",
              title: "Sağlık",
              value: 4
            },
            {
              id: "mali bünye",
              title: "Mali Bünye",
              value: 5
            }
          ]}
        />
        <FilterItem
          id="city"
          url="/data/cities"
          parentId="degiskenlerAccordion"
          title="Şehir"
          filterDatas={bolgeler}
          equalSearch
        />
        <FilterItem
          id="date"
          url="/data/years"
          parentId="degiskenlerAccordion"
          title="Yıl"
        />
      </div>
    </section>
  );
};

VariablesView.propTypes = {};

export default VariablesView;
