import React, { Component } from "react";
import ControlView from "./control";
import PreviewView from "./preview";

class MainView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriesData: [],
      indicatorsData: [],
      valuesData: [],
      citiesData: [],
      datesData: []
    };
  }

  render() {
    return (
      <section className="main-view border-bottom">
        <div className="top-box-border" />
        <div className="container">
          <div className="d-flex flex-lg-row flex-sm-column flex-column justify-content-center">
            <ControlView />
            <PreviewView />
          </div>
        </div>
      </section>
    );
  }
}

MainView.propTypes = {};

export default MainView;
