const bolgeler = [
  {
    id: "tümü",
    title: "Tümü",
    value: 0,
    type: -1
  },
  {
    id: "akdeniz",
    title: "Akdeniz",
    value: 19,
    type: 0
  },
  {
    id: "doğu anadolu",
    title: "Doğu Anadolu",
    value: 15,
    type: 0
  },
  {
    id: "ege",
    title: "Ege",
    value: 14,
    type: 0
  },
  {
    id: "güneydoğu anadolu",
    title: "Güneydoğu Anadolu",
    value: 13,
    type: 0
  },

  {
    id: "i̇ç anadolu",
    title: "İç Anadolu",
    value: 16,
    type: 0
  },
  {
    id: "karadeniz",
    title: "Karadeniz",
    value: 17,
    type: 0
  },
  {
    id: "marmara",
    title: "Marmara",
    value: 18,
    type: 0
  },
  {
    id: "akdeniz",
    title: "Akdeniz",
    value: 20,
    type: 1
  },
  {
    id: "batı anadolu",
    title: "Batı Anadolu",
    value: 6,
    type: 1
  },
  {
    id: "batı karadeniz",
    title: "Batı Karadeniz ",
    value: 5,
    type: 1
  },
  {
    id: "batı marmara",
    title: "Batı Marmara",
    value: 8,
    type: 1
  },
  {
    id: "doğu karadeniz",
    title: "Doğu Karadeniz",
    value: 7,
    type: 1
  },
  {
    id: "doğu marmara",
    title: "Doğu Marmara",
    value: 9,
    type: 1
  },
  {
    id: "ege",
    title: "Ege",
    value: 2,
    type: 1
  },
  {
    id: "güneydoğu anadolu",
    title: "Güneydoğu Anadolu",
    value: 1,
    type: 1
  },
  {
    id: "istanbul",
    title: "İstanbul",
    value: 21,
    type: 1
  },
  {
    id: "kuzeydoğu anadolu",
    title: "Kuzeydoğu Anadolu",
    value: 3,
    type: 1
  },
  {
    id: "orta anadolu",
    title: "Orta Anadolu",
    value: 4,
    type: 1
  },
  {
    id: "ortadoğu anadolu",
    title: "Ortadoğu Anadolu",
    value: 10,
    type: 1
  }
];

export default bolgeler;
