import React, {Component} from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Modal from "../components/modal";
import Dropdown from "../components/dropDown";
import {sendRequest} from "../services/httpService";
import checkImage from "../assets/check2.png";
import uncheckImage from "../assets/check.png";

class CategorySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      allSelected: false,
      currentSelected: {},
      selectedItems: [],
      searchText: "",
      modalShow: false
    };
    this.onItemClicked = this.onItemClicked.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.allSelectClickedTrue = this.allSelectClickedTrue.bind(this);
    this.allSelectClickedFalse = this.allSelectClickedFalse.bind(this);
    this.allCheckClicked = this.allCheckClicked.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data.length === prevState.data.length) {
      return null;
    } else if (prevState.searchText.length > 0) {
      return null;
    } else {
      return {
        data: nextProps.data
      };
    }
  }

  onSearch(type, search) {
    const { data, equalSearch } = this.props;
    const text = equalSearch ? (search === "Tümü" ? "" : search) : (search === "tümü" ? "" : search.toLocaleLowerCase());
    let result = [];

    if (text === "") {
      this.setState({ data, searchText: "" });
    } 
    
    else {
      if(equalSearch && type === "filter") {
        result = data.filter(d => {
          return d.bolge === text || d.istatistiki_bolge === text;
        });
        this.setState({ data: result, searchText: text });
      }

      else {
        result = data.filter(d => {
          return d.name.toString().toLocaleLowerCase().search(text) > -1;
        });
        this.setState({ data: result, searchText: text });
      }
    }
  }

  onItemClicked(d) {
    const { itemClickCallback, type, maxSelectSize } = this.props;
    const { selectedItems } = this.state;

    const newSelectedItems = [...selectedItems];
    const itemExist =
      newSelectedItems.filter(i => {
        return i.value === d.value;
      }).length > 0;
      
    const existingItem = itemExist
      ? newSelectedItems.filter(i => {
          return i.value === d.value;
        })[0]
      : null;

    if (itemExist) {
      const index = newSelectedItems.indexOf(existingItem);
      newSelectedItems.splice(index, 1);
      this.setState(
        {
          selectedItems: newSelectedItems
        },
        () => {
          itemClickCallback(type, newSelectedItems);
        }
      );
    } else {
      if(maxSelectSize === selectedItems.length) {
        toast.warn("En Fazla 20 Adet Gösterge Seçebilirsiniz.", {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

      newSelectedItems.push(d);
      this.setState(
        { currentSelected: d, selectedItems: newSelectedItems },
        () => {
          itemClickCallback(type, newSelectedItems);
        }
      );
    }
  }

  isItemExist(d, data) {
    const itemExist =
      data.filter(i => {
        return i.value === d.value;
      }).length > 0;
    const existingItem = itemExist
      ? data.filter(i => {
          return i.value === d.value;
        })[0]
      : null;

    return existingItem === null ? false : true;
  }

  allCheckClicked() {
    const { itemClickCallback, type, maxSelectSize } = this.props;
    const { data, allSelected } = this.state;
    let sizedData = [];

    if(!allSelected) {
      if(maxSelectSize) {
        sizedData = data.slice(0, maxSelectSize);
      }
  
      else {
        sizedData = data;
      }
  
      this.setState({ allSelected: true, selectedItems: sizedData }, () => {
        itemClickCallback(type, sizedData);
      });
    }

    else {
      this.setState(
        {
          currentSelected: {},
          allSelected: false,
          selectedItems: []
        },
        () => {
          itemClickCallback(type, []);
        }
      );
    }
  }

  allSelectClickedTrue() {
    const { itemClickCallback, type, maxSelectSize } = this.props;
    const { data } = this.state;
    let sizedData = [];

    if(maxSelectSize) {
      sizedData = data.slice(0, maxSelectSize);
    }

    else {
      sizedData = data;
    }

    this.setState({ allSelected: true, selectedItems: sizedData }, () => {
      itemClickCallback(type, sizedData);
    });
  }

  allSelectClickedFalse() {
    const { itemClickCallback, type } = this.props;

    this.setState(
      {
        currentSelected: {},
        allSelected: false,
        selectedItems: []
      },
      () => {
        itemClickCallback(type, []);
      }
    );
  }

  fetchMetaData(indicator) {
    const payload = {
      indicator: indicator
    };

    sendRequest("/data/meta-data", "post", null, payload)
      .then(res => {
        this.setState({ metaData: res.data });
      })
      .catch(err => {
        console.error(err);
      });
  }

  render() {
    const { data, selectedItems, modalShow, metaData, allSelected } = this.state;
    const { information, filterDatas, equalSearch, title } = this.props;

    return (
      <section className="category-search">
        <div className="category-search-top">
          <div>
            <img width="25" src={allSelected ? checkImage : uncheckImage} onClick={this.allCheckClicked} />
          </div>
          <div className="has-search">
            <span className="acshb-icon-search1 icon" />
            <input
              type="text"
              className="form-control"
              placeholder={title + " Giriniz"}
              onChange={e => {
                this.onSearch("text", e.target.value.toString());
              }}
            />
          </div>
          {filterDatas ? (
            <Dropdown
              buttonClass="btn btn-secondary"
              onSelectCallback={e => {
                equalSearch ? this.onSearch("filter", e.title.toString()) : this.onSearch("filter", e.id.toString());
              }}
              items={filterDatas}
            >
              <span className="acshb-icon-filter" />
            </Dropdown>
          ) : null}
        </div>
        <hr />
        <div className="category-search-bottom">
          <form className="category-search-bottom-form">
            {data.length === 0 ? (
              <p>Sonuç Bulunamadı</p>
            ) : (
              data.map(d => {
                return (
                  <div key={d.name}>
                    {information ? (
                      <span
                        className="modal-trigger-icon"
                        onClick={() => {
                          this.setState({ modalShow: true, metaData: null });
                          this.fetchMetaData(d.name);
                        }}
                      >
                        ?
                      </span>
                    ) : null}
                    <label
                      onChange={() => {
                        this.onItemClicked(d);
                      }}
                    >
                      <input
                        type="checkbox"
                        name="vehicle1"
                        value={this.isItemExist(d, selectedItems)}
                        checked={this.isItemExist(d, selectedItems)}
                      />
                      <span className="item-label" style={{color: (d.name === "Türkiye") ? "red" : "inherit"}}>{(d.name === "Türkiye") ? d.name.toUpperCase() : d.name}</span>
                    </label>
                  </div>
                );
              })
            )}
          </form>
        </div>
        {information ? (
          <Modal
            show={modalShow}
            title="Metaveri"
            hideCallback={() => {
              this.setState({ modalShow: false });
            }}
          >
            {metaData ? (
              <div className="section-metadata priveew-content">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <tbody>
                      <tr>
                        <th>Gösterge</th>
                        <td>{metaData.indicator}</td>
                      </tr>
                      <tr>
                        <th>Değer</th>
                        <td>{metaData.unit}</td>
                      </tr>
                      <tr>
                        <th>Tanım</th>
                        <td>{metaData.definition}</td>
                      </tr>
                      <tr>
                        <th>Yayınlanma Sıklığı</th>
                        <td>{metaData.frequency}</td>
                      </tr>
                      <tr>
                        <th>Yayınlanma Takvimi</th>
                        <td>{metaData.publish_schedule}</td>
                      </tr>
                      <tr>
                        <th>Kaynak</th>
                        <td>{metaData.source}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </Modal>
        ) : null}
      </section>
    );
  }
}

CategorySearch.propTypes = {
  data: PropTypes.array.isRequired,
  itemClickCallback: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  information: PropTypes.bool,
  filterDatas: PropTypes.array,
  equalSearch: PropTypes.bool,
  maxSelectSize: PropTypes.number,
  title: PropTypes.string.isRequired
};

export default CategorySearch;
