import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectedData } from "../actions/selectedData";
import { sendRequest } from "../services/httpService";
import CategorySearch from "./categorySearch";

class FilterItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selectedCount: 0,
      selectedData: null
    };

    this.fetchData = this.fetchData.bind(this);
    this.onUserSelect = this.onUserSelect.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { url } = this.props;

    sendRequest(url, "get", null, null)
      .then(res => {
        if (url === "/data/cities") {
          // note: türkiye başa geliyor yurtdışı ve bilinmeyen sona geliyor
          let newCities = [];
          const turkiye =
            res.data.filter(f => {
              return f.name === "Türkiye";
            }).length > 0
              ? res.data.filter(f => {
                  return f.name === "Türkiye";
                })[0]
              : null;
          const turkiyeIndex =
            turkiye === null ? -1 : res.data.indexOf(turkiye);
          newCities = this.array_move(res.data, turkiyeIndex, 0);
          const yurtdisi =
            res.data.filter(f => {
              return f.name === "Yurtdışı";
            }).length > 0
              ? res.data.filter(f => {
                  return f.name === "Yurtdışı";
                })[0]
              : null;
          const yurtdisiIndex =
            yurtdisi === null ? -1 : res.data.indexOf(yurtdisi);
          newCities = this.array_move(
            res.data,
            yurtdisiIndex,
            res.data.length - 1
          );
          const bilinmeyen =
            res.data.filter(f => {
              return f.name === "Bilinmeyen";
            }).length > 0
              ? res.data.filter(f => {
                  return f.name === "Bilinmeyen";
                })[0]
              : null;
          const bilinmeyenIndex =
            bilinmeyen === null ? -1 : res.data.indexOf(bilinmeyen);
          newCities = this.array_move(
            res.data,
            bilinmeyenIndex,
            res.data.length - 1
          );
          this.setState({ data: newCities });
        } else {
          this.setState({ data: res.data });
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  onUserSelect(type, data) {
    this.props.setSelectedData([type, data]);
    this.setState({ selectedCount: data.length });
  }

  render() {
    const {
      id,
      parentId,
      title,
      show,
      information,
      filterDatas,
      equalSearch,
      maxSelectSize
    } = this.props;
    const { data, selectedCount } = this.state;

    return (
      <React.Fragment>
        <div className="card filter-item">
          <div className="card-header" id={`${id}Baslik`}>
            <div className="row">
              <div className="col-12">
                <button
                  className="btn btn-block pt-3"
                  type="button"
                  data-toggle="collapse"
                  data-target={`#${id}`}
                  aria-expanded="true"
                  aria-controls={`${id}`}
                >
                  <table>
                    <tbody>
                      <tr align="left">
                        <td width="100%">
                          <span className="acshb-icon-chevron-down card-icon float-left pl-3" />
                          <strong className="float-left">{title}</strong>
                        </td>
                        <td width="">
                          <small>
                            <span>
                              Mevcut:<b>{data.length}</b>
                            </span>
                          </small>
                        </td>
                        <td width="">
                          <small>
                            <span>
                              Seçilen:<b>{selectedCount}</b>
                            </span>
                          </small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </button>
              </div>
              {/*<div className="col-4">*/}
              {/*  <button*/}
              {/*      className="btn btn-link pt-3"*/}
              {/*      type="button"*/}
              {/*      data-toggle="collapse"*/}
              {/*      data-target={`#${id}`}*/}
              {/*      aria-expanded="true"*/}
              {/*      aria-controls={`${id}`}*/}
              {/*  >*/}
              {/*    <span className="acshb-icon-chevron-down card-icon" />*/}
              {/*    {title}*/}
              {/*  </button>*/}
              {/*</div>*/}
              {/*<div className="col-4 pt-2">*/}
              {/*  <h6>*/}
              {/*    <button*/}
              {/*        className="btn"*/}
              {/*        data-toggle="collapse"*/}
              {/*        data-target={`#${id}`}*/}
              {/*        aria-expanded="true"*/}
              {/*        aria-controls={`${id}`}*/}
              {/*    >*/}
              {/*      Mevcut*/}
              {/*    </button>*/}

              {/*    <span>*/}
              {/*      <b>{data.length}</b>*/}
              {/*    </span>*/}
              {/*  </h6>*/}
              {/*</div>*/}
              {/*<div className="col-4 pt-2">*/}
              {/*  <h6>*/}
              {/*    Seçilen*/}
              {/*    <span>*/}
              {/*      <b>{selectedCount}</b>*/}
              {/*    </span>*/}
              {/*  </h6>*/}
              {/*</div>*/}
            </div>
          </div>
          <div
            id={id}
            className={["collapse", show ? "show" : ""].join(" ")}
            aria-labelledby={`${id}Baslik`}
            data-parent={`#${parentId}`}
          >
            <div className="card-body">
              <CategorySearch
                type={id}
                data={data}
                itemClickCallback={this.onUserSelect}
                information={information}
                filterDatas={filterDatas}
                equalSearch={equalSearch}
                maxSelectSize={maxSelectSize}
                title={title}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedData: data => dispatch(selectedData(data))
  };
};

FilterItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  show: PropTypes.bool,
  setSelectedData: PropTypes.func,
  information: PropTypes.bool,
  filterDatas: PropTypes.array,
  equalSearch: PropTypes.bool,
  maxSelectSize: PropTypes.number
};

export default connect(
  null,
  mapDispatchToProps
)(FilterItem);
