import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tabview from "../components/tabview";
import VariablesView from "../views/partialViews/variablesView";
import LayoutsView from "../views/partialViews/layoutsView";
import ShareViews from "./partialViews/shareViews";

class ControlView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        { id: "variables", component: VariablesView, title: "Değişkenler" },
        {
          id: "view",
          component: LayoutsView,
          title: "Görünüm"
        },
        {
          id: "share",
          component: ShareViews,
          title: "Paylaş"
        }
      ],
      active: false
    };
    this.onSidebarHide = this.onSidebarHide.bind(this);
  }

  onSidebarHide(e) {
    e.preventDefault();
    this.setState({ active: !this.state.active });
  }

  render() {
    const { data } = this.state;

    return (
      <section className={this.state.active ? "border-off" : " border-on"}>
        <div>
          <a
            className="controlViewToggleButton"
            title="Aç/Kapat"
            href="/"
            onClick={this.onSidebarHide}
          >
            <span
              className={
                this.state.active
                  ? "acshb-icon-chevron-right  d-none d-lg-block"
                  : "acshb-icon-chevron-left  d-none d-lg-block"
              }
            />
          </a>
        </div>
        <div className={this.state.active ? "" : "slide-left"}>
          <div
            className={this.state.active ? "control-view-off" : " control-view"}
          >
            <section className="mb-3">
              <p className="application-title">SGK VERİ UYGULAMASI</p>
            </section>
            <section>
              <Tabview className="mt-5" defaultSelectedIndex={0} data={data} />
            </section>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedData: state.selectedData,
    selectedLayouts: state.selectedLayouts
  };
};

ControlView.propTypes = {
  confirm: PropTypes.bool
};

export default connect(
  mapStateToProps,
  null
)(ControlView);
