import React from "react";
import logo from "../assets/logoacshb.svg";

function Toolbar(props) {
  return (
    <header className="toolbar border-bottom">
      <div className="container d-flex flex-wrap justify-content-between align-items-center py-4">
        <a href="/">
        <img
          className="toolbar-logo img-fluid"
          width="150"
          src={logo}
          alt="Uygulama Logosu"
        />
        </a>
        <div className="toolbar-links d-flex align-items-center">
          <a href="https://www.facebook.com/sgksosyalmedya/" className="ml-3" title="Facebook" target="_blank" rel="noopener noreferrer">
            <span className="acshb-icon-facebook" />
          </a>
          <a href="https://twitter.com/sgksosyalmedya" className="ml-3" title="Twitter" target="_blank" rel="noopener noreferrer">
            <span className="acshb-icon-twitter" />
          </a>
          <a href="https://www.youtube.com/sgksosyalmedya" className="ml-3" title="Youtube" target="_blank" rel="noopener noreferrer">
            <span className="acshb-icon-youtube" />
          </a>
          <a href="https://www.instagram.com/sgksosyalmedya/?hl=tr" className="ml-3" title="Instagram" target="_blank" rel="noopener noreferrer">
            <span className="acshb-icon-instagram" />
          </a>
          <a href="/" className="mx-3 pl-1 border-left" title="Ana Sayfa">
            <span className="acshb-icon-home" />
          </a>
        </div>
      </div>
    </header>
  );
}

Toolbar.propTypes = {};

export default Toolbar;
