import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loading } from "../actions/loading";
import { toast } from "react-toastify";
import ReactEcharts from "echarts-for-react";
import { sendRequest } from "../services/httpService";
import logo from "../assets/logoacshb.svg";

class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCity:
        props.selectedData.cities.length === 0
          ? ""
          : props.selectedData.cities[0].name,
      indicators: props.selectedData.indicators,
        units: props.selectedData.units.map(d => {
            return d.name}),
      data: [],
      citiesData: props.selectedData.cities.map(d => {
        return {
          label: d.name,
          value: d.name
        };
      }),
      selectedCities: props.selectedData.cities.map(d => {
        return d.name;
      })
    };
    this.fetchData = this.fetchData.bind(this);
    this.getOption = this.getOption.bind(this);
    //this.onIndicatorChange = this.onIndicatorChange.bind(this);
    this.onCityChange = this.onCityChange.bind(this);
    this.generateColor = this.generateColor.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    this.fetchData();
  }

  fetchData() {
    const { selectedData, setLoading } = this.props;
    const { activeCity } = this.state;
    const payload = {
      year: selectedData.dates.map(d => {
        return d.name;
      }),
      city: [activeCity],
      category: selectedData.categories.map(d => {
        return d.name;
      }),
      indicator: selectedData.indicators.map(d => {
        return d.name;
      }),
        unit: selectedData.units.map(d => {
            return d.name;}),
      offset: 0,
      count: 50
    };

    setLoading(true);
    sendRequest("/data/statistic", "post", null, payload)
      .then(res => {
        this.setState({ data: res.data }, () => {
          setLoading(false);
        });
      })
      .catch(err => {
        toast.error("Veri alınırken bir hata meydana geldi!", {
          position: toast.POSITION.TOP_RIGHT
        });
        console.error(err);
      });
  }

  getOption(selectedData, selectedLayouts, data, activeCity) {
    let xAxis = {};
    let yAxis = {};

    switch (selectedLayouts.graphics.type) {
      case "1":
        xAxis = {
          type: "category",
          data: data ? data.dates : []
        };
        yAxis = {
          type: "value"
        };
        break;
      case "2":
        xAxis = {
          type: "value"
        };
        yAxis = {
          type: "category",
          data: data ? data.dates : []
        };
        break;
      default:
        break;
    }

    const option = {
      title: {
        text: activeCity
      },
      tooltip: {
        trigger: "axis",
          formatter: function (params) {
              let colorCircle = color => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
              let tooltip = '<span style="color:#ffffff">' + params[0].axisValue + '</span>';
              params.forEach((item, index, arr) => {
                  let tooltipInfo = '<br><span style="color:#ffffff">' + colorCircle(item.color) + item.seriesName + ': ' + item.data + ' ' + data.data[index].unit +'</span>';
                  tooltip += tooltipInfo;
              });
              return tooltip;
          }

      },
      legend: {
        data: [],
        x: "center",
        y: "bottom",
        itemGap: 5,
        type: "scroll",
        orient: "horizontal"
      },
      grid: {
        height: "400"
      },
      toolbox: {
        show: true,
        feature: {
          magicType: {
            show: true,
            type: ["line", "bar"],
            title: {
              line: "Çizgi",
              bar: "Çubuk"
            }
          },
          saveAsImage: {
            show: true,
            title: "Kaydet"
          }
        }
      },
      calculable: true,
      xAxis: [xAxis],
      yAxis: [yAxis],
      series: []
    };
    if (data && data.data) {
      data.data.forEach((statistic, index, arr) => {
        option.series.push({
          name: statistic.indicator,
          type: "bar",
          data: statistic.values,
            unit:statistic.unit,
          itemStyle: {
            normal: {
              color: this.generateColor(index)
            }
          }
        });
        option.legend.data.push(statistic.indicator);
      });
    }
    return option;
  }

  generateColor(index) {
    const { selectedLayouts } = this.props;

    switch (index) {
      case 0:
        return selectedLayouts.graphics.colors[0];
      case 1:
        return selectedLayouts.graphics.colors[1];
      case 2:
        return selectedLayouts.graphics.colors[2];
      case 3:
        return selectedLayouts.graphics.colors[0];
      case 4:
        return selectedLayouts.graphics.colors[1];
      case 5:
        return selectedLayouts.graphics.colors[2];
      case 6:
        return selectedLayouts.graphics.colors[0];
      case 7:
        return selectedLayouts.graphics.colors[1];
      case 8:
        return selectedLayouts.graphics.colors[2];
      case 9:
        return selectedLayouts.graphics.colors[0];
      case 10:
        return selectedLayouts.graphics.colors[1];
      case 11:
        return selectedLayouts.graphics.colors[2];
      default:
        break;
    }
  }

  onCityChange(e) {
    this.setState(
      {
        activeCity: e.target.value
      },
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    const { selectedData, selectedLayouts } = this.props;
    const { data, activeCity } = this.state;

    return (
      <div className="section-charts preview-content">
        <div className="row">
          <div className="col-12">
            <form>
              <div className="form-group">
                <select className="form-control" onChange={this.onCityChange}>
                  {selectedData.cities.map(c => {
                    return (
                      <option key={c.name} value={c.name}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </form>
          </div>
          <div className="col-12">
            {
              data.length === 0 || (data.data && data.data.length === 0) ?
              <p>Veri Yok</p>
              :
              <ReactEcharts
              option={this.getOption(
                selectedData,
                selectedLayouts,
                data,
                activeCity
              )}
              style={{ width: "95%", height: "550px" }}
              opts={{ renderer: "canvas" }}
              notMerge={true}
            />
            }
          </div>
          <div className="row">
            <div className="col-12 float-left ml-4 mb-4">
              <small className="float-left text-muted kaynak-text">Kaynak: </small>
              <img   src={logo}  alt="logo" height="20"/>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoading: data => dispatch(loading(data))
  };
};

Charts.propTypes = {
  selectedData: PropTypes.object,
  selectedLayouts: PropTypes.object,
  setLoading: PropTypes.func
};

export default connect(
  null,
  mapDispatchToProps
)(Charts);
