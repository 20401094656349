import * as types from "../actions/types";

const initialState = {
  layouts: [],
  graphics: {
    type: "1",
    colors: localStorage.getItem("colors") === null ? ["#ed1c24", "#b51117", "#828282"] : localStorage.getItem("colors").split(",")
  },
  sizes: 0
};

const selectedLayouts = (state = initialState, action) => {
  let result = null;

  switch (action.type) {
    case types.selectedLayouts:
      result = Object.assign({}, action.payload);
      localStorage.setItem("colors", action.payload.graphics.colors);
      break;
    default:
      result = state;
      break;
  }

  return result;
};

export default selectedLayouts;
