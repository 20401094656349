import * as types from "../actions/types";

const initialState = false;

const loading = (state = initialState, action) => {
  let result = null;

  switch (action.type) {
    case types.loading:
      result = action.payload;
      break;
    default:
      result = state;
      break;
  }

  return result;
};

export default loading;