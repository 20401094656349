import React from "react";

function ShareViews(props) {
  return (
    <section className="shared-view border-bottom">
      <div className="card">
        <div className="row">
          <div className="col-12 text-center">
            <div
              id="layout"
              className="collapse show"
              aria-labelledby="layoutBaslik"
              data-parent="#degiskenlerAccordion"
            >
              <div className="card-body text-center">
                <div className="btn-group btn-group-toggle">
                  <div className="share-links d-flex align-items-center">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=http://veri.sgk.gov.tr/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-5"
                      title="Facebook"
                    >
                      <span className="acshb-icon-facebook" />
                    </a>
                    <a
                      href="http://twitter.com/share?text=SGK Veri Uygulaması&url=http://veri.sgk.gov.tr"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-5"
                      title="Twitter"
                    >
                      <span className="acshb-icon-twitter" />
                    </a>
                    <a
                      href="mailto:?subject=SGK Veri Uygulaması&amp;body=Uygulama Linki: http://veri.sgk.gov.tr"
                      rel="noopener noreferrer"
                      className="p-5"
                      title="E-Posta"
                    >
                      <span className="acshb-icon-mail" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ShareViews;
